import Link from 'next/link'
import React from "react";

export function NavLink({href, onClick, children, tabIndex}: Readonly<{ href?: string, onClick?: () => void, children: React.ReactNode, tabIndex?: number }>) {
  return (
    <Link
      href={href ?? ""}
      onClick={onClick}
      className="inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
      tabIndex={tabIndex}
    >
      {children}
    </Link>
  )
}
